/**
 * HomepageFeatured component to display two blocks on the homepage
 */

// Import statements
import React, {useState} from "react"
import {Link} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image";

/**
 * Define the HomepageFeatured constant
 * @param featuredArray
 * @returns {JSX.Element|string}
 * @constructor
 */
const HomepageFeatured = ({featuredArray}) => {

    // make the topitems clickable
    const [featuredState, setFeaturedState] = useState(1)

    /**
     * Function to strike through list items
     * @param event
     * @param index
     * @param currentFeaturedState
     */
    function addFeaturedState(event, index, currentFeaturedState) {

        // it is already active
        if (index !== currentFeaturedState) {

            // do not follow link yet
            event.preventDefault();

            // make the other one active
            setFeaturedState((currentFeaturedState === 1 ? 2 : 1));
        }
    }

    // when there are not enough featured posts
    if (featuredArray.length < 2) {
        return '';
    }

    // define the images
    const image0 = (featuredArray[0].featuredImage !== null) ? getImage(featuredArray[0].featuredImage.node.localFile) : ""
    const image1 = (featuredArray[1].featuredImage !== null) ? getImage(featuredArray[1].featuredImage.node.localFile) : ""

    // return the information
    return (
        <div className="row" id="topitems">
            <div className={featuredState === 1 ? "col-sm-9 left-topitem active" : "col-sm-3 left-topitem"}>
                <Link to={featuredArray[0].uri} title={featuredArray[0].title}
                      onClick={e => addFeaturedState(e, 1, featuredState)}>
                    <div className="image_wrapper">
                        <GatsbyImage image={image0} alt={featuredArray[0].title} title={featuredArray[0].title}/>
                    </div>
                </Link>
                <div className="topitem-content">
                    <Link to={featuredArray[0].uri} title={featuredArray[0].title}
                          onClick={e => addFeaturedState(e, 1, featuredState)}>
                        <h2>{featuredArray[0].title}</h2>
                        <Description post={featuredArray[0]}/>
                        <ReadMoreButton post={featuredArray[0]}/>
                    </Link>
                </div>
            </div>
            <div className={featuredState === 2 ? "col-sm-9 right-topitem active" : "col-sm-3 right-topitem"}>
                <Link to={featuredArray[0].uri} title={featuredArray[0].title}
                      onClick={e => addFeaturedState(e, 2, featuredState)}>
                    <div className="image_wrapper">
                        <GatsbyImage image={image1} alt={featuredArray[0].title} title={featuredArray[0].title}/>
                    </div>
                </Link>
                <div className="topitem-content">
                    <Link to={featuredArray[1].uri} title={featuredArray[1].title}
                          onClick={e => addFeaturedState(e, 2, featuredState)}>
                        <h2>{featuredArray[1].title}</h2>
                        <Description post={featuredArray[1]}/>
                        <ReadMoreButton post={featuredArray[1]}/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

/**
 * Export the HomepageFeatured
 */
export default HomepageFeatured

/**
 * Truncate a string
 * @param string
 * @param maxLength
 * @returns {string|string}
 */
const truncateString = (string = '', maxLength = 140) =>
    string.length > maxLength
        ? `${string.substring(0, maxLength)}…`
        : string

/**
 * Remove HTML tags from string
 * @param string
 * @param maxLength
 * @returns {string|string}
 */
const removeTags = (string = '') =>
    `${string.replace(/(<([^>]+)>)/gi, "")}`

/**
 * Description
 * @param post
 * @returns {JSX.Element}
 * @constructor
 */
const Description = ({post}) => {

    // start with empty line
    let description = '';

    // when an intro exists, use that one (recipes)
    if ("introWysiwyg" in post) {
        description = truncateString((removeTags(post.introWysiwyg) || ''), 140) + '...'

        // other post types
    } else {
        description = truncateString((removeTags(post.excerpt) || ''), 140) + '...'
    }

    // return the component
    return (
        <p>{description}</p>
    )
}


/**
 * Read More Button
 * @param post
 * @returns {JSX.Element}
 * @constructor
 */
const ReadMoreButton = ({post}) => {
    return (
        <span title={post.title} className={`btn btn-white-transparent btn-sm`}>
            <span className="read-more">Lees meer</span>
            <span className="icon icon-resize-full-alt"></span>
        </span>
    )
}