/**
 * HomepageFeatured component to display two blocks on the homepage
 */

// Import statements
import React from "react"
import {Link} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image";

/**
 * Define the HomepageCollections constant
 * @param featuredCollections
 * @param featuredCollectionImages
 * @returns {JSX.Element|string}
 * @constructor
 */
const HomepageCollections = ({featuredCollections, featuredCollectionImages}) => {

    // when there are not enough featured posts
    if (featuredCollections.length < 4 || featuredCollectionImages.length < 1) {
        return '';
    }

    // return the information
    return (
        <div className="row" id="collection_items">
            <TopItemContent post={featuredCollections[0]} images={featuredCollectionImages}/>
            <TopItemContent post={featuredCollections[1]} images={featuredCollectionImages}/>
            <TopItemContent post={featuredCollections[2]} images={featuredCollectionImages}/>
            <TopItemContent post={featuredCollections[3]} images={featuredCollectionImages}/>
        </div>
    )
}

/**
 * Export the HomepageCollections constant
 */
export default HomepageCollections

/**
 * Get Image
 * @param post
 * @returns {JSX.Element}
 * @constructor
 */
const GetImage = ({post, imageNode}) => {

    const image = (imageNode.localFile !== null) ? getImage(imageNode.localFile) : ""

    return (
        <Link to={post.node?.uri} title={post.node?.name}>
            <div className="image_wrapper placeholder bg-primary">
                {image && (
                    <GatsbyImage image={image} alt={post.node?.name} title={post.node?.name}/>
                )}
            </div>
        </Link>
    )
}

/**
 * Find the image Object by ID
 * @param imageId
 * @param images
 * @returns {string|*}
 */
const getImageNode = (imageId, images) => {

    // when there are nodes found
    if (images.length > 0) {

        // loop through them
        for (let i = 0; i < images.length; i++) {

            // when the image is found
            if (images[i].databaseId === imageId) {

                // return the full object
                return images[i];
            }
        }
    }

    // otherwise return an empty string
    return '';
}

/**
 * Top item content
 * @param post
 * @returns {JSX.Element}
 * @constructor
 */
const TopItemContent = ({post, images}) => {

    let imageNode = getImageNode(parseInt(post.node?.recipeCollectionImage), images)

    return (
        <div className="col-sm-3">
            <GetImage post={post} imageNode={imageNode}/>
            <div className="collection_item-content text-center">
                <Link to={post.node?.uri} title={post.node?.name} className={`text-decoration-none counter`}>
                    20 Recepten
                </Link>
                <Link to={post.node?.uri} title={post.node?.name} className={`text-decoration-none title`}>
                    {post.node?.name}
                </Link>
            </div>
        </div>
    )
}
