/**
 * Body component to markup the body element
 * This is not a wrapper.
 * Example usage:
 * <Body bodyClass="single single-post "/>
 */

// Import statements
import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"

/**
 * Define the Body constant
 * @param bodyClass
 * @returns {JSX.Element}
 * @constructor
 */
const Body = ({bodyClass}) => {

    // return the information
    return (
        <Helmet>
            <body className={bodyClass}/>
        </Helmet>
    )
}

/**
 * Default value for the bodyClass variable
 * @type {{bodyClass: string}}
 */
Body.defaultProps = {
    bodyClass: ``
}

/**
 * Define the datatype of the bodyClass variable
 * @type {{bodyClass: Requireable<string>}}
 */
Body.propTypes = {
    bodyClass: PropTypes.string
}

/**
 * Export the Body variable
 */
export default Body
