/**
 * Homepage
 */

// Import statements
import React from "react"
import {graphql} from "gatsby"
import "../styles/files/home.scss"

import Card from "../components/common/card"
import Layout from "../components/layout"
import Seo from "../components/common/seo"
import HomeHeader from "../components/home/home-header"
import HomepageFeatured from "../components/home/home-featured";
import Body from "../components/body";
import HomepageCollections from "../components/home/home-collections";

/**
 * Define the Home constant
 * @param data
 * @param homepageFeaturedCollections
 * @param homepageFeaturedCollectionImages
 * @returns {JSX.Element}
 * @constructor
 */
const Home = ({
                  data,
                  pageContext: {homepageFeaturedCollections, homepageFeaturedCollectionImages},
              }) => {

    // combine both spotlight sources and sort the results by date
    let featuredArray = [...data.featuredRecipes.nodes, ...data.featuredPosts.nodes];
    featuredArray.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
    })

    // when there are no recipes
    if (!data.allWpRecipe.nodes.length) {
        return (
            <Layout isHomePage>
                <Seo title="All posts"/>
                <p>
                    No blog posts found. Add posts to your WordPress site and they'll
                    appear here!
                </p>
            </Layout>
        )
    }

    // return the information
    return (
        <Layout isHomePage>
            <Body bodyClass="home"/>
            <Seo title="All posts"/>

            <HomepageFeatured featuredArray={featuredArray}/>

            <HomeHeader title="Tip van de redactie" id="home-header-collection"/>
            <HomepageCollections featuredCollections={homepageFeaturedCollections} featuredCollectionImages={homepageFeaturedCollectionImages}/>

            <HomeHeader title="Vers van de pers" id="home-header-collection"/>
            <div className="row g-4 g-sm-2 g-md-4" id="card-wrapper">
                {data.allWpRecipe.nodes.map((post, index) => {
                    return (<Card post={post} index={index} key={index} featured="false"/>)
                })}
            </div>

            <HomeHeader title="Inspiratie" id="home-header-collection"/>
            <div className="row g-4 g-sm-2 g-md-4" id="card-wrapper">
                {data.allWpPost.nodes.map((post, index) => {
                    return (<Card post={post} index={index} key={index}/>)
                })}
            </div>

        </Layout>
    )
}

/**
 * Export the Home variable
 */
export default Home

/**
 * Get the homepage information with GraphQL
 * @type {StaticQueryDocument}
 */
export const pageQuery = graphql`
  query Home {
    
    # get featured items
    featuredRecipes: allWpRecipe(
      filter: {spotlightHomepage: {eq: "on"}}
      sort: {order: DESC, fields: date}
      limit: 2
    ) {
      nodes {
        ...recipeFields
        introWysiwyg
        featuredImage { ...featuredImageFields }
      }
    }
    featuredPosts: allWpPost(
      filter: {spotlightHomepage: {eq: "on"}}
      sort: {order: DESC, fields: date}
      limit: 2
    ) {
      nodes {
        ...postFields
        featuredImage { ...featuredImageFields }
      }
    }
    
    # get the latest 4 blogposts
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 4
      skip: 0
    ) {
      nodes {
        ...postFields
        featuredImage { ...cardImageFields }
      }
    }
    
    # get the latest 12 recipes
    allWpRecipe(
      sort: { fields: [date], order: DESC }
      limit: 12
      skip: 0
    ) {
      nodes {
        ...recipeFields
        featuredImage { ...cardImageFields }
      }
    }
    
  }
`